import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, parceriaStore, filtroPorTabelaStore } from '@/store';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import _ from 'lodash';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Nome', value: 'nome', show: true },
            { text: 'Data de início', value: 'data_inicio', show: true },
            { text: 'Data de término', value: 'data_termino', show: true },
            { text: 'Entrada de cupons', value: 'total_entrada', show: true },
            { text: 'Saída de cupons', value: 'total_saida', show: true },
            { text: 'Saldo de cupons', value: 'total_saldo', show: true }
        ];
        this.situacaoOptions = [
            { text: 'Expirada', value: 1 },
            { text: 'Não Expirada', value: 2 }
        ];
        this.selectedTab = 2;
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
        this.tabelaRef = null;
        this.items = [];
        this.menu = false;
        this.selectPeriodDate = [];
        this.selectedSituacao = null;
        this.selectCheckbox = null;
        this.loadingSave = false;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_delete;
        }
    }
    get headersFiltered() {
        return _.filter(this.headers, item => item.show);
    }
    showFiltroPorTabela() {
        const form = this.$refs.filtroPorTabela;
        form.show('parceria');
    }
    async onUpdateHeaders(headers) {
        this.headers = headers;
    }
    get formattedDate() {
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        }
        else if (this.selectPeriodDate.length === 1) {
            return `${this.formatDate(this.selectPeriodDate[0])}`;
        }
        return '';
    }
    clearFilters() {
        this.selectPeriodDate = [];
        this.search = '';
        this.selectedSituacao = null;
        this.$forceUpdate();
    }
    get isClearFiltersDisabled() {
        return (this.selectPeriodDate.length === 0 &&
            this.search === '' &&
            this.selectedSituacao === null);
    }
    async onChangeSelectAllCheckbox(val) {
        if (this.loadingSave) {
            return;
        }
        this.loadingSave = true;
        await filtroPorTabelaStore.filtroPorTabelaBuscaFiltroSessao('list_campanhas');
        this.headers.forEach(header => {
            header.show = val.includes(header.value);
        });
        const items = this.headers.filter(item => item.show);
        await filtroPorTabelaStore.filtroPorTabelaCreate({
            items,
            table_name: 'list_campanhas'
        });
        this.loadingSave = false;
    }
    get filteredCampanhas() {
        return this.items.filter(campanha => {
            // Filtragem por situação
            const today = new Date();
            const dataInicio = new Date(campanha.data_inicio);
            const dataTermino = new Date(campanha.data_termino);
            let situacaoMatch = true;
            if (this.selectedSituacao === 1) {
                // Expirada
                situacaoMatch = dataTermino < today;
            }
            else if (this.selectedSituacao === 2) {
                // Não Expirada
                situacaoMatch = dataTermino >= today;
            }
            // Filtragem por data de criação
            const dateMatch = this.selectPeriodDate.length === 0 ||
                (dataInicio >= new Date(this.selectPeriodDate[0]) &&
                    dataInicio <= new Date(this.selectPeriodDate[1]));
            // Filtragem por pesquisa de nome
            const searchMatch = campanha.nome
                .toLowerCase()
                .includes(this.search.toLowerCase());
            return situacaoMatch && dateMatch && searchMatch;
        });
    }
    // public async deleteItem(id: number) {
    //   this.$swal({
    //     title: 'Confirma a exclusão da parceria?',
    //     text: 'Após exclusão a parceria não pode ser recuperada!',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     cancelButtonText: 'Cancelar',
    //     confirmButtonText: 'Sim, eu confirmo!',
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       this.loading = true;
    //       // @ts-ignore
    //       await parceriaStore.deleteParceria(id);
    //       // @ts-ignore
    //       await parceriaStore.getParcerias();
    //       this.loading = false;
    //     }
    //   });
    // }
    async created() {
        this.$nextTick(() => {
            this.tabelaRef = this.$refs.tabela;
        });
    }
    async mounted() {
        this.loading = true;
        this.items = await parceriaStore.getParceriasCampanha();
        this.selectCheckbox = this.headers.map(item => item.value);
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-parcerias-edit', params: { id } });
    }
};
List = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            ImprimirTabelaComponent,
            TextExpandableComponent,
            PeriodSelectorComponent,
            ButtonComponent,
            CustomTabsComercialComponent
        }
    })
], List);
export default List;
